import Vue from 'vue'
import VueRouter from 'vue-router'

const defaultPath = { name: 'Endpoints' }

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Main',
    redirect: defaultPath,
    exact: true,
  },
  {
    path: '/endpoints',
    name: 'Endpoints',
    component: () => import('@/pages/PageEndpoints.vue'),
  },
  {
    path: '/endpoints/:endpoint/storages',
    name: 'Storages',
    component: () => import('@/pages/PageStorages.vue'),
  },
  {
    path: '/endpoints/:endpoint/storages/:storage/classes',
    name: 'Classes',
    component: () => import('@/pages/PageClasses.vue'),
  },
  {
    path: '/endpoints/:endpoint/storages/:storage/table',
    name: 'Storage table',
    component: () => import('@/pages/PageStorageTable.vue'),
  },
  {
    path: '/endpoints/:endpoint/extra-funcs',
    name: 'Extra funcs',
    component: () => import('@/pages/PageExtraFuncs.vue'),
  },
  {
    path: '/endpoints/:endpoint/prefixes-types',
    name: 'Prefixes n types',
    component: () => import('@/pages/PagePrefixesnTypes.vue'),
  },
  {
    path: '/systems',
    name: 'Systems',
    component: () => import('@/pages/PageSystems.vue'),
  },
  {
    path: '/systems/:endpoint',
    name: 'Systems endpoint',
    component: () => import('@/pages/PageSystemsEndpoint.vue'),
  },
  {
    path: '/systems/:endpoint/:system/rights',
    name: 'All rights',
    component: () => import('@/pages/PageRights.vue'),
  },
  {
    path: '/systems/:endpoint/:system/classes-rights',
    name: 'Classes rights',
    component: () => import('@/pages/PageClassesRights.vue'),
  },
  {
    path: '/systems/:endpoint/:system/attributes-rights',
    name: 'Attributes rights',
    component: () => import('@/pages/PageAttributesRights.vue'),
  },
  {
    path: '/systems/:endpoint/:system/source-system-rights',
    name: 'Source system rights',
    component: () => import('@/pages/PageSourceSystemRights.vue'),
  },
  {
    path: '/systems/:endpoint/:system/subscribers',
    name: 'System subscribers',
    component: () => import('@/pages/PageSystemSubscribers.vue'),
  },
  {
    path: '/systems/:endpoint/:system/subscribers/:subscriber',
    name: 'Subscriptions',
    component: () => import('@/pages/PageSubscriptions.vue'),
  },
  {
    path: '/systems/:endpoint/:system/subscribers/:subscriber/:subscriptions/relations',
    name: 'Relations',
    component: () => import('@/pages/PageRelations.vue'),
  },
  {
    path: '/endpoints/:endpoint/model-versions',
    name: 'ModelVersions',
    component: () => import('@/pages/PageModelVersions.vue'),
  },
  {
    path: '/systems/:endpoint/active',
    name: 'Active subscriptions',
    component: () => import('@/pages/PageActiveSubscriptions.vue'),
  },
  {
    path: '/queues',
    name: 'Queues',
    component: () => import('@/pages/PageQueues.vue'),
  },
  {
    path: '/constants',
    name: 'Сonstants',
    component: () => import('@/pages/PageConstants.vue'),
  },
  {
    path: '/xml-templates',
    name: 'Xml templates',
    component: () => import('@/pages/PageXmlTemplates.vue'),
  },
  {
    path: '/routes',
    name: 'Routes',
    component: () => import('@/pages/PageAcceptRoutes.vue'),
  },
  {
    path: '/routes/:endpoint/',
    name: 'Routes endpoint',
    component: () => import('@/pages/PageAcceptRoutesEndpoint.vue'),
  },
  {
    path: '/routes/:endpoint/:route',
    name: 'Routes rules',
    component: () => import('@/pages/PageAcceptRoutesRules.vue'),
  },
  {
    path: '/subscribers',
    name: 'Subscribers',
    component: () => import('@/pages/PageSubscribers.vue'),
  },
  {
    path: '/subscribers/:emails',
    name: 'Subscribers emails',
    component: () => import('@/pages/PageEmailsNClasses.vue'),
  },
  {
    path: '/brokers',
    name: 'Brokers',
    component: () => import('@/pages/PageBrokers.vue'),
  },
  {
    path: '/standart-prefixes',
    name: 'Standart Prefixes',
    component: () => import('@/pages/PageStandartPrefixes.vue'),
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: () => import('@/pages/PageTasks.vue'),
  },
  {
    path: '/applications-rights',
    name: 'Applications Rights',
    component: () => import('@/pages/PageApplicationsRights.vue'),
  },
  {
    path: '/licenses',
    name: 'Licenses',
    component: () => import('@/pages/PageLicenses.vue'),
  },
  {
    path: '/migrations',
    name: 'Migrations',
    component: () => import('@/pages/PageMigrations.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes,
})

export default router
