import store from '@/store'
import axios from 'axios'
import { v4 as uuid } from 'uuid'

const client = axios.create({
  baseURL: import.meta.env.VITE_SERVER_URL,
  withCredentials: true,
  timeout: 1000 * 60 * 20,
})

client.interceptors.request.use(config => {
  store.commit('Loading', true)
  return {
    ...config,
    headers: {
      ...config.headers,
      location: location.href,
      'X-Request-Id': uuid(),
    },
  }
})

client.interceptors.response.use(
  response => {
    store.commit('Loading', false)
    store.commit('rebuildCache', response.headers['rebuild-cache'])
    store.commit('rebuildCacheSubscribers', response.headers['rebuild-cache-subscribers'])
    store.commit('rebuildCacheSubscribersByEndpoint', response.headers['rebuild-cache-subscribers'])
    return response.data
  },
  error => {
    store.commit('Loading', false)
    if (error.response.status === 403) {
      store.dispatch('Logout', /auth$/.test(error.response.config?.url))
    }
    if (error.response.headers['is-fatal']) {
      store.commit('toast', [error.response.data, 'error'])
    }
    throw new Error(`Axios (lazy): ${error.message}. ${error.response.data}`)
  },
)

export default client
