import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import './polyfills'

import globalMixin from '@/mixins/global.mixin.js'
Vue.mixin(globalMixin)

import Notifications from 'vue-notification'
Vue.use(Notifications)

import localize from '@/filters/localize'
Vue.filter('localize', localize)

import '@/assets/styles/vue-select/vue-select.scss'
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

import frag from 'vue-frag'
Vue.directive('frag', frag)

import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
Vue.use(Toast, {
  position: 'bottom-right',
  timeout: 100000,
  closeOnClick: false,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: false,
  showCloseButtonOnHover: false,
  closeButton: 'button',
  icon: true,
  rtl: false,
  transition: 'Vue-Toastification__fade',
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
