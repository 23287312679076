<template>
  <div v-if="ready" :key="appKey" id="app">
    <transition name="right">
      <div v-if="needRebuildCache" @click="rebuild" class="rebuild">
        <span>
          {{ 'Для применения изменений необходимо обновить кеш' | localize }}
        </span>
      </div>
    </transition>
    <transition>
      <div v-if="needRebuildCacheSubscribers" @click="rebuildSubscribers" class="rebuild">
        <span>
          {{ 'Для применения изменений необходимо обновить кеш подписок' | localize }}
        </span>
      </div>
    </transition>
    <transition>
      <div v-if="needRebuildCacheSubscribersByEndpoint" @click="rebuildSubscribersByEndpoint" class="rebuild">
        <span>
          {{ 'Для применения изменений необходимо обновить кеш подписок для точки' | localize }}
        </span>
      </div>
    </transition>
    <router-view :key="appKey" />
  </div>
</template>

<script>
import api from './utils/api'

export default {
  name: 'app',
  data() {
    return {
      ready: false,
    }
  },
  created() {
    this.auth()
  },
  methods: {
    async auth() {
      try {
        await this.$store.dispatch('Auth')
      } catch {}
      if (!this.user.isLoggedIn) {
        this.$store.dispatch('login')
        // this.auth()
      } else if (!this.user.access) {
        if (
          confirm(
            this.localize(
              'У пользователя отсутствует доступ к данному приложению. \nОбратитесь к администратору. \nАвторизоваться в другой учетной записи?',
            ),
          )
        ) {
          this.$store.dispatch('Logout')
        }
      } else if (this.user.access) {
        this.ready = true
        this.updateNavigation()
        this.$store.dispatch('GetLicense')
      }
    },
    async updateNavigation() {
      const v = await api.post('navigation', this.store)
      this.$store.commit(
        'Navigation',
        v.map(e => `${e.label} (${this.localize(e.type)})`).join(' / '),
      )
    },
    rebuild() {
      api.get('rebuildCache').then(data => {
        const error =
          data?.OperationResult?.Message || data?.InvalidPackage?.Message
        if (typeof error === 'string') {
          this.toast(error, 'error')
        }
      })
      this.$store.commit('rebuildCache', null)
    },
    rebuildSubscribers() {
      api.get(`rebuildCacheSubscribers`).then(data => {
        const error =
            data?.OperationResult?.Message || data?.InvalidPackage?.Message
        if (typeof error === 'string') {
          this.toast(error, 'error')
        }
      })
      this.$store.commit('rebuildCacheSubscribers', null)
    },
    rebuildSubscribersByEndpoint() {
      api.get(`rebuildCacheSubscribers/${this.endpointUid}`).then(data => {
        const error =
            data?.OperationResult?.Message || data?.InvalidPackage?.Message
        if (typeof error === 'string') {
          this.toast(error, 'error')
        }
      })
      this.$store.commit('rebuildCacheSubscribersByEndpoint', null)
    },
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    appKey() {
      return this.$store.state.appKey
    },
    store() {
      return {
        ...this.$store.state.systems,
        ...this.$route.params,
      }
    },
    needRebuildCache() {
      return this.$store.state.rebuildCache
    },
    needRebuildCacheSubscribers() {
      return this.$store.state.rebuildCacheSubscribers
    },
    needRebuildCacheSubscribersByEndpoint() {
      return this.$store.state.rebuildCacheSubscribersByEndpoint
    },
    toasts() {
      return this.$store.state.toasts
    },
  },
  watch: {
    store: {
      handler() {
        this.updateNavigation()
      },
    },
    toasts: {
      handler(v) {
        if (v.length) {
          for (const [text, type] of v) {
            this.toast(text, type)
          }
          this.$store.commit('clearToasts')
        }
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.rebuild {
  cursor: pointer;
  opacity: 0.98;
  z-index: 100000;
  position: fixed;
  padding: 8px;
  top: 66px;
  right: 15px;
  border-radius: 10px;
  font-weight: 600;
  background-color: rgb(255, 246, 235);
  border: 2px solid rgba(255, 0, 0, 0.5);
}
.right-enter-active,
.right-leave-active {
  transition: 0.4s ease-in;
}
.right-enter,
.right-leave-to {
  transform: translateX(150%);
}
</style>

<style lang="scss">
@import '@/assets/styles/variables.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/fonts.scss';

html {
  font-size: $font-size;
  overflow-x: hidden;
}

body {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-text;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: $color-red-dark;
  @include transition();

  &:hover {
    color: $color-red-bright;
  }
}

p {
  margin: 0;
}

* {
  scrollbar-color: $color-scroll transparent;
  scrollbar-width: thin;
  -webkit-overflow-scrolling: auto;

  &::-webkit-scrollbar {
    width: rem(8px);
  }

  &::-webkit-scrollbar-thumb {
    border-right: 4px solid rgba(0, 0, 0, 0);
    background-clip: content-box;
    background-color: $color-scroll;
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

table {
  box-sizing: border-box;
  padding: 20px;
  td {
    padding: 7px;
    vertical-align: middle;
    &:not(.non-break) {
      word-break: break-word;
    }
    &.non-break {
      padding: 3px;
      white-space: nowrap;
      button {
        font-size: 10px;
        padding: 4px 13px;
        line-height: 15px;
      }
    }
  }
  thead {
    font-weight: 500;
    font-size: 15px;
    td {
      padding-left: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      background-color: white;
      position: sticky;
      top: 0;
      z-index: 10;
    }
  }
  tbody {
    tr {
      &:not(:first-child) {
        td {
          border-top: 1px dotted rgba(0, 0, 0, 0.15);
        }
      }
      &:nth-child(odd) {
        $v: 242;
        td {
          background: rgb($v, $v, 255);
        }
      }
      .button--stroke {
        background: white;
      }
    }
  }
}

h1 {
  margin-left: 25px;
  margin-top: 15px;
  font-weight: 500;
}

.split {
  &-wrapper {
    display: flex;
  }
  &-item:first-child {
    flex-grow: 1;
  }
  &-item:last-child {
    flex-grow: 1.5;
  }
}

.required .input__label::after {
  content: '*';
}

.import {
  display: inline-block;
  margin-left: 20px;
  input {
    position: absolute;
    left: -1000px;
  }
  label {
    cursor: pointer;
  }
}

.return {
  transform: rotate(90deg);
  cursor: pointer;
  svg {
    fill: white;
  }
}

.split-modal {
  .modal__container {
    width: 88rem;
    //max-height: 58rem;
    //transform: scale(0.9);
    .split {
      display: flex;
      .split-block {
        flex-grow: 1;
        &:first-child {
          padding-right: 10px;
        }
        &:last-child {
          padding-left: 10px;
        }
        &:not(:first-child) {
          &:not(:last-child) {
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }
    }
  }
}
</style>
