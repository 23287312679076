import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/utils/api'
import apiLazy from '@/utils/api-lazy'
import endpoints from '@/store/modules/endpoints'
import systems from '@/store/modules/systems'
import LicenseToast from '@/components/LicenseToast'

Vue.use(Vuex)

const getDefaultLanguage = () => {
  const possible = ['ru', 'en']
  const priority = 'ru'
  const language = (
    localStorage.getItem('language') || window.navigator.language
  ).split('-')[0]
  return possible.find(e => e == language) || priority
}

export default new Vuex.Store({
  state: {
    toasts: [],
    appKey: 0,
    language: getDefaultLanguage(),
    user: {},
    isLoading: 0,
    navigation: null,
    rebuildCache: null,
    rebuildCacheSubscribers: null,
    rebuildCacheSubscribersByEndpoint: null,
  },
  mutations: {
    toast(state, payload) {
      state.toasts.push(payload)
    },
    clearToasts(state) {
      state.toasts = []
    },
    rebuildCache(state, payload) {
      state.rebuildCache = payload
    },
    rebuildCacheSubscribers(state, payload) {
      state.rebuildCacheSubscribers = payload
    },
    rebuildCacheSubscribersByEndpoint(state, payload) {
      state.rebuildCacheSubscribersByEndpoint = payload
    },
    Navigation(state, payload) {
      state.navigation = payload
    },
    Refresh(state) {
      state.appKey++
    },
    Loading(state, payload) {
      if (payload == 'disable') state.isLoading = -1
      else if (payload == null) state.isLoading = 0
      else {
        if (payload) state.isLoading++
        else state.isLoading = Math.max(state.isLoading - 1, 0)
      }
    },
    SetLanguage(state, lang) {
      localStorage.setItem('language', lang)
      state.language = lang
    },
    SetUser(state, payload) {
      if (payload.login) {
        state.user = {
          isLoggedIn: true,
          username: payload.name || '???',
          access: payload.access,
        }
      } else {
        state.user = {
          isLoggedIn: false,
        }
      }
      state.appKey++
    },
    resetUser(state) {
      state.user = {}
    },
  },
  actions: {
    async GetLicense(context) {
      try {
        let response = await api.get('licenses/check')
        if (response.status !== 'success') {
          const content = {
            component: LicenseToast,
            props: {
              text: response.message,
            },
          }
          this._vm.$toast(content, {
            type: response.status,
            timeout: false,
            position: 'top-center',
            icon: false,
            draggablePercent: 2,
          })
        }
      } catch (e) {
        console.error(e)
      }
      setTimeout(() => context.dispatch('GetLicense'), 60 * 60 * 1_000)
    },
    async SetLanguage(context, lang) {
      context.commit('SetLanguage', lang)
      await api.patch('lang/' + lang)
      sessionStorage.clear()
    },
    async Auth(context) {
      const data = await apiLazy.get('auth')
      context.commit('SetUser', data)
    },
    async Logout(context, lazy) {
      context.commit('resetUser')
      if (!lazy) {
        await api.post('logout')
      }
      context.commit('Refresh')
      context.dispatch('login')
    },
    login() {
      const front = encodeURIComponent(window.location.origin)
      const href = encodeURIComponent(window.location.href)
      const back = import.meta.env.VITE_SERVER_URL.replace(/(^\/)|(\/$)/g, '')
      window.location =
        window.location.origin +
        '/' +
        back +
        '/oauth/login' +
        '?front=' +
        front +
        '&href=' +
        href +
        '&back=' +
        encodeURIComponent(back)
    },
  },
  getters: {},
  modules: {
    endpoints,
    systems,
  },
})
