const globalMixin = {
  methods: {
    localize(v) {
      return this.$options.filters.localize(v)
    },
    toast(text, type) {
      type =
        {
          warn: 'warning',
          ok: 'success',
        }[type] || type
      this.$toast[type || 'info'](this.localize(text))
    },
    returnPage(payload) {
      let parts = this.$route.path.split('/').slice(0, -1)
      if (typeof payload === 'string') {
        parts.push(payload)
      } else if (typeof payload === 'number' && payload > 1) {
        parts = parts.slice(0, (payload - 1) * -1)
      }
      this.$router.push({
        path: parts.join('/'),
      })
    },
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading > 0
    },
    endpointUid() {
      return this.$route.params.endpoint
    },
    systemUid() {
      return this.$route.params.system
    },
  },
}

export default globalMixin
