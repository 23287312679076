const state = {
  newRights: null,
}

const mutations = {
  openNewRights(state, id) {
    state.newRights = id
  },
  return(state) {
    state.newRights = null
  },
}

const actions = {}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters,
}
