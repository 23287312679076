const state = {
  openCreateFusekiStorage: false,
}

const mutations = {
  openCreateFusekiStorage(state, payload) {
    state.openCreateFusekiStorage = payload
  },
}

const actions = {}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters,
}
